













import { DataTableColDef, DataTableDef, DataTableRowDef } from '@/app_code/DataTable'
import { Modal } from '@/app_code/Modals/Modal'
import ZonePickerModalOptions from '@/app_code/Modals/Options/ZonePickerModalOptions'
import DataTable from '@/components/UI/Elements/DataTable/DataTable.vue'
import { FranchiseModel, ZoneModel } from 'truemarket-modules/src/models/api/admin'
import { Services } from 'truemarket-modules/src/services'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    DataTable
  }
})
export default class Edit extends Vue {
  private franchise: FranchiseModel | null = null

  private zoneDtDef = new DataTableDef(new DataTableRowDef(row => row.ZoneId, [
    new DataTableColDef('TM Code', row => row.TMCode),
    new DataTableColDef('TM Name', row => row.TMName)
  ]))

  mounted () {
    this.updateFranchise()
  }

  updateFranchise () {
    Services.API.Admin.Franchises.Get(this.$route.params.id).then((franchise) => {
      this.franchise = franchise
    })
  }
}
